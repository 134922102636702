import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Title = makeShortcode("Title");
const Video = makeShortcode("Video");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Digital innovation`}</h1>
    <PageDescription mdxType="PageDescription">
    CREATIWEB is a web studio who helps store owners increase their sales.
    We create appealing and functional ecommerce experiences for remarkable brands on shopify.
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Who we are</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Our expertise</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Shopify partner</AnchorLink>
    </AnchorLinks>
    <h3>{`Who we are`}</h3>
    <p>{`We are a web studio who helps small business and start ups quickly and efficiently transition from in-store to online selling.
Our service include design and development project.`}</p>
    <h3>{`Our expertise`}</h3>
    <p>{`We believe a brand can make decisions faster by having access to the right knowledge.
We know how to grow an online presence, acquire customers and increase conversions by
developing a solid foundation for a growing brand.`}</p>
    <h3>{`Shopify partner`}</h3>
    <p>{`Serving Shopify ecosystem since 2019, CREATIWEB digital solutions is trusted for delivering high-end product & services,
from store set-up, app development, migration to marketing digital and SEO.`}</p>
    <p>{`We work together with you to identify your goals, strengths, and resources and to create a strategy that you can actually implement.`}</p>
    <Title mdxType="Title">Who We Are</Title>
    <Video src="/videos/who-we-are.mp4" loop autoPlay preload="auto" type="video/mp4" mdxType="Video">
  <track kind="captions" default src="/videos/vtt/who-we-are.vtt" srcLang="en" />
    </Video>
    <Caption mdxType="Caption">
  CREATIWEB digital solutions | We are a web studio specialized in developing Shopify ecommerce websites.
    </Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      